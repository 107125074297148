import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useMemo, useState } from 'react';
import Tabs from '@shared/ui/navigation/Tabs';
import { useAppSelector } from '@app/store/Hooks';
import { ContainerColumn, ContainerRow } from '@components/styled';
import { BalancePieChart, OverallBalanceCurve } from '@entities/current-user';
import BalanceChangeDetails from './BalanceChangeDetails';
import { BALANCE_GRAPH_DATA } from './mocks';
import { getDefaultGraphRange, mapBalanceGraphData } from './utils';
import { BalanceGraphPeriod } from './constants';
const BalanceContainer = styled(ContainerRow)(() => ({
    width: 'auto',
    height: 'auto',
    alignItems: 'center',
    gap: '34px',
}));
const BalanceGraphContainer = styled(ContainerColumn)(props => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.l,
    alignItems: 'end',
}));
const ChartTabsContainer = styled.div();
const OverallBalance = ({ className = 'undefined', handleExchangeConnect = undefined, wallets, }) => {
    const { currentUserProfile } = useAppSelector(state => state.profile);
    const priorityWalletType = (currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.type) === 'CLIENT' ?
        'wallet' : 'portfolio';
    const [activeBalancePie, setActiveBalancePie,] = useState();
    const [period, setPeriod] = useState(BalanceGraphPeriod['30D']);
    const onBalancePieHover = (balancePie, isHovered) => {
        setActiveBalancePie(isHovered ? balancePie : undefined);
    };
    const { overallBalance, overallChangeCurrency, overallChangePercent, } = useMemo(() => wallets.reduce((acc, wallet) => {
        acc.overallBalance += Number(wallet.totalAssetsUsd) || 0;
        acc.overallChangeCurrency += wallet.balance_diff_in_currency || 0;
        acc.overallChangePercent += wallet.balance_diff_in_percentage || 0;
        return acc;
    }, {
        overallBalance: 0,
        overallChangeCurrency: 0,
        overallChangePercent: 0,
    }), [wallets]);
    const balance = activeBalancePie ? Number(activeBalancePie.totalAssetsUsd) : overallBalance;
    const changeCurrency = activeBalancePie ?
        activeBalancePie.balance_diff_in_currency :
        overallChangeCurrency;
    const changePercent = activeBalancePie ?
        activeBalancePie.balance_diff_in_percentage :
        overallChangePercent;
    const { data, defaultRange, } = useMemo(() => {
        switch (period) {
            case BalanceGraphPeriod['30D']:
                return {
                    data: mapBalanceGraphData(BALANCE_GRAPH_DATA.slice(0, 30)),
                    defaultRange: getDefaultGraphRange(BalanceGraphPeriod['30D']),
                };
            case BalanceGraphPeriod['90D']:
                return {
                    data: mapBalanceGraphData(BALANCE_GRAPH_DATA.slice(0, 90)),
                    defaultRange: getDefaultGraphRange(BalanceGraphPeriod['90D']),
                };
            case BalanceGraphPeriod['180D']:
                return {
                    data: mapBalanceGraphData(BALANCE_GRAPH_DATA.slice(0, 180)),
                    defaultRange: getDefaultGraphRange(BalanceGraphPeriod['180D']),
                };
            case BalanceGraphPeriod['1Year']:
            default:
                return {
                    data: mapBalanceGraphData(BALANCE_GRAPH_DATA),
                    defaultRange: getDefaultGraphRange(BalanceGraphPeriod['1Year']),
                };
        }
    }, [period]);
    const handlePeriodChange = (activeTab) => {
        setPeriod(activeTab);
    };
    return (_jsxs(BalanceContainer, { className: className, children: [_jsx(BalancePieChart, { width: 200, height: 200, data: wallets, priorityWalletType: priorityWalletType, handleExchangeConnect: handleExchangeConnect, onHover: onBalancePieHover }), 
            // eslint-disable-next-line max-len
            // TODO-FIX-MOCKS: backend should provide some status regarding the exchange connection
            // !activeBalancePie?.exchange.error && (
            true && (_jsx(BalanceChangeDetails, { balance: balance, balance_change: changeCurrency, percent_change: changePercent, haveWallets: wallets.length > 0 })), _jsxs(BalanceGraphContainer, { children: [_jsx(ChartTabsContainer, { children: _jsx(Tabs, { variant: 'chart-tabs', tabs: [
                                { value: BalanceGraphPeriod['1Year'], label: '1 Year' },
                                { value: BalanceGraphPeriod['180D'], label: '180 D' },
                                { value: BalanceGraphPeriod['90D'], label: '90 D' },
                                { value: BalanceGraphPeriod['30D'], label: '30 D' },
                            ], onChange: handlePeriodChange, activeTab: period }) }), _jsx(OverallBalanceCurve, { data: [], defaultRange: defaultRange })] })] }));
};
export default OverallBalance;
